//
//// Custom Theming for Angular Material
//// For more information: https://material.angular.io/guide/theming
//@use '@angular/material' as mat;
//// Plus imports for other components in your app.
//
//// Include the common styles for Angular Material. We include this here so that you only
//// have to load a single css file for Angular Material in your app.
//// Be sure that you only ever include this mixin once!
//@include mat.core();
//
//// Define the palettes for your theme using the Material Design palettes available in palette.scss
//// (imported above). For each palette, you can optionally specify a default, lighter, and darker
//// hue. Available color palettes: https://material.io/design/color/
//$project-partner-front-end-primary: mat.define-palette(mat.$indigo-palette);
//$project-partner-front-end-accent: mat.define-palette(mat.$pink-palette, A200, A100, A400);
//
//// The warn palette is optional (defaults to red).
//$project-partner-front-end-warn: mat.define-palette(mat.$red-palette);
//
//// Create the theme object. A theme consists of configurations for individual
//// theming systems such as "color" or "typography".
//$project-partner-front-end-theme: mat.define-light-theme((
//  color: (
//    primary: $project-partner-front-end-primary,
//    accent: $project-partner-front-end-accent,
//    warn: $project-partner-front-end-warn,
//  )
//));
//
//// Include theme styles for core and each component used in your app.
//// Alternatively, you can import and @include the theme mixins for each component
//// that you are using.
//@include mat.all-component-themes($project-partner-front-end-theme);
//
//
//html, body { height: 100%; }
//body { margin: 0; font-family: Roboto, "Helvetica Neue", sans-serif; }
//
//@import url("https://fonts.googleapis.com/css?family=PT+Sans:400,700");
//@import url("https://fonts.googleapis.com/css?family=Inconsolata:400,700");
@use '@angular/material' as mat;

// TODO(v15): As of v15 mat.legacy-core no longer includes default typography styles.
//  The following line adds:
//    1. Default typography styles for all components
//    2. Styles for typography hierarchy classes (e.g. .mat-headline-1)
//  If you specify typography styles for the components you use elsewhere, you should delete this line.
//  If you don't need the default component typographies but still want the hierarchy styles,
//  you can delete this line and instead use:
//    `@include mat.legacy-typography-hierarchy(mat.define-legacy-typography-config());`
@include mat.all-legacy-component-typographies();
@include mat.legacy-core();


:root {
  /* Fonts */
  --primary-font-family: Arial, Helvetica, sans-serif;
  --icon-family: Material Icons;

  /* Typography */
  --heading-1-font-style: normal;
  --heading-1-font-weight: normal;
  --heading-1-font-size: 88px;
  --heading-1-line-height: 96px;
  --heading-1-letter-spacing: 1.5px;

  --heading-2-font-style: normal;
  --heading-2-font-weight: normal;
  --heading-2-font-size: 57px;
  --heading-2-line-height: 60px;
  --heading-2-letter-spacing: 0.5px;

  --heading-3-font-style: normal;
  --heading-3-font-weight: normal;
  --heading-3-font-size: 48px;
  --heading-3-line-height: 50px;
  --heading-3-letter-spacing: 0.5px;

  --heading-4-font-style: normal;
  --heading-4-font-weight: bold;
  --heading-4-font-size: 32px;
  --heading-4-line-height: 40px;
  --heading-4-letter-spacing: 0.25px;

  --heading-5-font-style: normal;
  --heading-5-font-weight: bold;
  --heading-5-font-size: 28px;
  --heading-5-line-height: 32px;
  --heading-5-letter-spacing: 0.25px;

  --heading-6-font-style: normal;
  --heading-6-font-weight: normal;
  --heading-6-font-size: 18px;
  --heading-6-line-height: 32px;
  --heading-6-letter-spacing: 0.25px;

  --sub-title-1-font-style: normal;
  --sub-title-1-font-weight: normal;
  --sub-title-1-font-size: 15px;
  --sub-title-1-line-height: 28px;
  --sub-title-1-letter-spacing: 0.15px;

  --sub-title-2-font-style: normal;
  --sub-title-2-font-weight: normal;
  --sub-title-2-font-size: 14px;
  --sub-title-2-line-height: 22px;
  --sub-title-2-letter-spacing: 0.1px;

  --body-1-font-style: normal;
  --body-1-font-weight: normal;
  --body-1-font-size: 16px;
  --body-1-line-height: 24px;
  --body-1-letter-spacing: 0.5px;

  --body-2-font-style: normal;
  --body-2-font-weight: normal;
  --body-2-font-size: 14px;
  --body-2-line-height: 20px;
  --body-2-letter-spacing: 0.25px;

  --button-font-style: normal;
  --button-font-weight: 500;
  --button-font-size: 14px;
  --button-line-height: 36px;
  --button-letter-spacing: 1.25px;

  --caption-font-style: normal;
  --caption-font-weight: normal;
  --caption-font-size: 12px;
  --caption-line-height: 20px;
  --caption-letter-spacing: 0.4px;


  --overline-font-style: normal;
  --overline-font-weight: normal;
  --overline-font-size: 12px;
  --overline-line-height: 32px;
  --overline-letter-spacing: 2px;

  /* Icons */
  --x-large-icon-font-style: normal;
  --x-large-icon-font-weight: normal;
  --x-large-icon-font-size: 40px;
  --x-large-icon-line-height: 100%;

  --large-icon-font-style: normal;
  --large-icon-font-weight: normal;
  --large-icon-font-size: 36px;
  --large-icon-line-height: 100%;

  --medium-icon-font-style: normal;
  --medium-icon-font-weight: normal;
  --medium-icon-font-size: 24px;
  --medium-icon-line-height: 100%;

  --dense-icon-font-style: normal;
  --dense-icon-font-weight: normal;
  --dense-icon-font-size: 20px;
  --dense-icon-line-height: 100%;

  --small-icon-font-style: normal;
  --small-icon-font-weight: normal;
  --small-icon-font-size: 16px;
  --small-icon-line-height: 100%;

  --x-small-icon-font-style: normal;
  --x-small-icon-font-weight: normal;
  --x-small-icon-font-size: 12px;
  --x-small-icon-line-height: 100%;

  /* Colors */
  --primary-green-color: #00B76B;
  --primary-crm-color: #166ee1;
  --blue-crm-7-color: #b3d9ff;
  --green-1-color: #388e3c;
  --green-2-color: #43a047;
  --green-3-color: #66bb6a;
  --green-4-color: #81c784;
  --green-5-color: #a5d6a7;
  --green-6-color: #c8e6c9;
  --green-7-color: #e8f5e9;

  --primary-indigo-color: #3f51b5;
  --indigo-1-color: #3949ab;
  --indigo-2-color: #5c6bc0;
  --indigo-3-color: #7986cb;
  --indigo-4-color: #9fa8da;
  --indigo-5-color: #c5cae9;
  --indigo-6-color: #e8eaf6;

  --primary-grey-color: #9e9e9e;
  --grey-1-color: #212121;
  --grey-2-color: #424242;
  --grey-3-color: #616161;
  --grey-4-color: #87859f;
  --grey-5-color: #bdbdbd;
  --grey-6-color: #e0e0e0;
  --grey-7-color: #eeeeee;
  --grey-8-color: #f5f5f5;
  --grey-9-color: #fafafa;

  --primary-black-color: #000000;
  --primary-white-color: #ffffff;
  --secondary-color: #212121;
  --error-color: #ff5252;
  --success-color: #00B76B;
  --warning-color: #fb8c00;
  --info-color: #2196f3;

}

$mat-realtycms-green: (
  50: #E4F6ED,
  100: #BDE9D2,
  200: #91DBB5,
  300: #5DCD97,
  400: #26C281,
  500: #00B76B,
  600: #00A760,
  700: #009553,
  800: #008347,
  900: #006431,
  contrast: (
    50: #000000,
    100:#000000,
    200:#000000,
    300:#000000,
    400:#000000,
    500:#ffffff,
    600:#ffffff,
    700:#ffffff,
    800:#ffffff,
    900:#ffffff,
  )
);

$mat-realtycms-orange: (
  50: #fff1e0,
  100: #feddb3,
  200: #fdc680,
  300: #fcaf4d,
  400: #fc9d26,
  500: #fb8c00,
  600: #fa8400,
  700: #fa7900,
  800: #f96f00,
  900: #f85c00,
  A100: #ffffff,
  A200: #fff1eb,
  A400: #ffceb8,
  A700: #ffbc9f,
  contrast: (
    50: #000000,
    100:#000000,
    200:#000000,
    300:#000000,
    400:#000000,
    500:#ffffff,
    600:#ffffff,
    700:#ffffff,
    800:#ffffff,
    900:#ffffff,
  ),
);

$mat-century21-primary-palettes: (
  50: #b2a071,
  100:#b2a071,
  200:#b2a071,
  300:#b2a071,
  400:#b2a071,
  500:#b2a071,
  600:#b2a071,
  700:#b2a071,
  800:#b2a071,
  900:#b2a071,
  A100: #b2a071,
  A200: #b2a071,
  A400: #b2a071,
  A700: #b2a071,
  contrast: (
    50: #ffffff,
    100: #ffffff,
    200: #ffffff,
    300: #ffffff,
    400: #ffffff,
    500: #ffffff,
    600: #ffffff,
    700: #ffffff,
    800: #ffffff,
  ),
);

$mat-sky-blue: (
  50: #e1f0ff,
  100: #b3d9ff,
  200: #80bfff,
  300: #4da6ff,
  400: #2690ff,
  500: #166ee1,
  600: #0f56b3,
  700: #0c3f8f,
  800: #08296b,
  900: #041347,
  A100: #cce6ff,
  A200: #99ccff,
  A400: #66b3ff,
  A700: #3388ff,
  contrast: (
    50: rgba(0, 0, 0, 0.87),
    100: rgba(0, 0, 0, 0.87),
    200: rgba(0, 0, 0, 0.87),
    300: rgba(0, 0, 0, 0.87),
    400: #fff,
    500: #fff,
    600: #fff,
    700: #fff,
    800: #fff,
    900: #fff,
    A100: rgba(0, 0, 0, 0.87),
    A200: #fff,
    A400: #fff,
    A700: #fff,
  ),
);

$mat-cyan: (
  50: #E0F7FA,
  100: #B2EBF2,
  200: #80DEEA,
  300: #4DD0E1,
  400: #26C6DA,
  500: #00BCD4,
  600: #00ACC1,
  700: #0097A7,
  800: #00838F,
  900: #006064,
  A100: #84FFFF,
  A200: #18FFFF,
  A400: #00E5FF,
  A700: #00B8D4,
  contrast: (
    50: #000000,
    100:#000000,
    200:#000000,
    300:#000000,
    400:#000000,
    500:#ffffff,
    600:#ffffff,
    700:#ffffff,
    800:#ffffff,
    900:#ffffff,
  ),
);

$my-primary: mat.define-palette($mat-realtycms-green);
$my-orange: mat.define-palette($mat-realtycms-orange);
$my-blue: mat.define-palette($mat-sky-blue);
$my-cyan: mat.define-palette($mat-cyan);
//$my-accent: mat.define-palette(mat.$pink-palette, A200, A100, A400);
$century21-primary: mat.define-palette($mat-century21-primary-palettes);

$my-theme: mat.define-light-theme((
  color: (
    primary: $my-primary,
    accent: $my-orange,
  )
));

$my-crm-theme: mat.define-light-theme((
  color: (
    primary: $my-blue,
    accent: $my-orange,
  )
));

.default-theme {
  @include mat.all-legacy-component-themes($my-theme);
}

.crm-theme {
  @include mat.all-legacy-component-themes($my-crm-theme);
}

@include mat.all-legacy-component-themes($my-theme);

.century-21-theme {
  @include mat.legacy-button-theme(mat.define-light-theme((
    color: (
      primary: $century21-primary,
      accent: $my-orange,
    )
  )));
  --primary-green-color: #b2a071;

  .mat-progress-bar-buffer {
    background-color: #dad4bf
  }
}

body {
  background: var(--background-default);
}

html,
body {
  margin: 0;
  padding: 0;
  font-family: "PT Sans", sans-serif;
}

.heading-1 {
  font-family: var(--primary-font-family);
  font-style: var(--heading-1-font-style);
  font-weight: var(--heading-1-font-weight);
  font-size: var(--heading-1-font-size);
  line-height: var(--heading-1-line-height);
  letter-spacing: var(--heading-1-letter-spacing);
}

.heading-2 {
  font-family: var(--primary-font-family);
  font-style: var(--heading-2-font-style);
  font-weight: var(--heading-2-font-weight);
  font-size: var(--heading-2-font-size);
  line-height: var(--heading-2-line-height);
  letter-spacing: var(--heading-2-letter-spacing);
}


.heading-3 {
  font-family: var(--primary-font-family);
  font-style: var(--heading-3-font-style);
  font-weight: var(--heading-3-font-weight);
  font-size: var(--heading-3-font-size);
  line-height: var(--heading-3-line-height);
  letter-spacing: var(--heading-3-letter-spacing);
}


.heading-4 {
  font-family: var(--primary-font-family);
  font-style: var(--heading-4-font-style);
  font-weight: var(--heading-4-font-weight);
  font-size: var(--heading-4-font-size);
  line-height: var(--heading-4-line-height);
  letter-spacing: var(--heading-4-letter-spacing);
}


.heading-5 {
  font-family: var(--primary-font-family);
  font-style: var(--heading-5-font-style);
  font-weight: var(--heading-5-font-weight);
  font-size: var(--heading-5-font-size);
  line-height: var(--heading-5-line-height);
  letter-spacing: var(--heading-5-letter-spacing);
}


.heading-6 {
  font-family: var(--primary-font-family);
  font-style: var(--heading-6-font-style);
  font-weight: var(--heading-6-font-weight);
  font-size: var(--heading-6-font-size);
  line-height: var(--heading-6-line-height);
  letter-spacing: var(--heading-6-letter-spacing);
}

.subtitle-1 {
  font-family: var(--primary-font-family);
  font-style: var(--sub-title-1-font-style);
  font-weight: var(--sub-title-1-font-weight);
  font-size: var(--sub-title-1-font-size);
  line-height: var(--sub-title-1-line-height);
  letter-spacing: var(--sub-title-1-letter-spacing);
}

.subtitle-2 {
  font-family: var(--primary-font-family);
  font-style: var(--sub-title-2-font-style);
  font-weight: var(--sub-title-2-font-weight);
  font-size: var(--sub-title-2-font-weight);
  line-height: var(--sub-title-2-line-height);
  letter-spacing: var(--sub-title-2-letter-spacing);
}


.body-1 {
  font-family: var(--primary-font-family);
  font-style: var(--body-1-font-style);
  font-weight: var(--body-1-font-weight);
  font-size: var(--body-1-font-size);
  line-height: var(--body-1-line-height);
  letter-spacing: var(--body-1-letter-spacing);
}


.body-2 {
  font-family: var(--primary-font-family);
  font-style: var(--body-2-font-style);
  font-weight: var(--body-2-font-weight);
  font-size: var(--body-2-font-size);
  line-height: var(--body-2-line-height);
  letter-spacing: var(--body-2-letter-spacing);
}

.global-button {
  font-family: var(--primary-font-family);
  font-style: var(--button-font-style);
  font-weight: var(--button-font-weight);
  font-size: var(--button-font-size);
  line-height: var(--button-line-height);
  letter-spacing: var(--button-letter-spacing);
  text-transform: uppercase;
  cursor: pointer;
}


.caption {
  font-family: var(--primary-font-family);
  font-style: var(--caption-font-style);
  font-weight: var(--caption-font-weight);
  font-size: var(--caption-font-size);
  line-height: var(--caption-line-height);
  letter-spacing: var(--caption-letter-spacing);
}

.overline {
  font-family: var(--primary-font-family);
  font-style: var(--overline-font-style);
  font-weight: var(--overline-font-weight);
  font-size: var(--overline-font-size);
  line-height: var(--overline-line-height);
  letter-spacing: var(--overline-letter-spacing);
  text-transform: uppercase;
}

.x-large-icon {
  font-style: var(--x-large-icon-font-style);
  font-weight: var(--x-large-icon-font-weight);
  font-size: (--x-large-icon-font-size);
  line-height: var(--x-large-icon-line-height);
}

.large-icon {
  font-style: var(--large-icon-font-style);
  font-weight: var(--large-icon-font-weight);
  font-size: (--large-icon-font-size);
  line-height: var(--large-icon-line-height);
}

.medium-icon {
  font-style: var(--medium-icon-font-style);
  font-weight: var(--medium-icon-font-weight);
  font-size: (--medium-icon-font-size);
  line-height: var(--medium-icon-line-height);
}

.dense-icon {
  font-style: var(--dense-icon-font-style);
  font-weight: var(--dense-icon-font-weight);
  font-size: (--dense-icon-font-size);
  line-height: var(--dense-icon-line-height);
}

.small-icon {
  font-style: var(--small-icon-font-style);
  font-weight: var(--small-icon-font-weight);
  font-size: (--small-icon-font-size);
  line-height: var(--small-icon-line-height);
}

.x-small-icon {
  font-style: var(--x-small-icon-font-style);
  font-weight: var(--x-small-icon-font-weight);
  font-size: (--x-small-icon-font-size);
  line-height: var(--x-small-icon-line-height);
}

.small-icon-button {
  display: flex;

  .small-icon-button-container {
    height: 38px;
    display: flex;
    align-items: center;

    .small-icon-button-icon-container {
      height: 16px;

      .small-icon-button-icon {
        position: absolute;
        height: 16px;
        width: 16px;
        font-size: 16px;
        line-height: 16px;
      }

      .small-icon-button-img {
        position: absolute;
        width: 15px;
        height: 15px;
      }
    }

    .small-icon-button-text-container {
      height: 38px;
      display: flex;
      align-items: center;
      margin-left: 16px !important;

      .small-icon-button-text {
        height: 16px;
        line-height: 16px;
        font-size: 16px;
      }
    }

    .small-icon-button-text-img-container {
      margin-left: 18px;
    }

  }
}
